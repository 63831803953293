import PriceDisplay from "../util/PriceDisplay";

const Summary = function({data}) {

    const calculate = function(data) {

        let max;
        let min;
        const current = data.values[data.values.length - 1];
        const previous = data.values[data.values.length - 2];

        data.values.forEach(i => {
            if (!max || max.price < i.price) {
                max = i;
            }

            if (!min || min.price > i.price) {
                min = i;
            }
            console.log(i);
        });

        return {
            max,
            min,
            previous,
            current
        }
    }

    const summary = calculate(data);

    console.log(summary);

    return (
        <>
            <div className="card py-3 px-2 shadow-sm">
                <ul className="list-group list-group-flush px-4">
                    <li className="list-group-item">آخر تعديل كان بتاريخ {summary.current.date} حيث تم { summary.previous.price > summary.current.price? 'تخفيض': 'رفع'} السعر ب {Math.abs(summary.previous.price - summary.current.price)} فلس و بنسبة {((summary.previous.price > summary.current.price? 1 - summary.current.price / summary.previous.price: 1- summary.previous.price / summary.current.price) * 100).toFixed(1) + "%"}</li>
                    <li className="list-group-item">أعلى سعر بلغ {summary.max.price} فلس في تاريخ {summary.max.date} حيث بلغ سعر التنكه (٢٠ لتر) <PriceDisplay price={summary.max.price * 20}/> </li>
                    <li className="list-group-item">أقل سعر بلغ {summary.min.price} فلس في تاريخ {summary.min.date} حيث بلغ سعر التنكه (٢٠ لتر) <PriceDisplay price={summary.min.price * 20}/> </li>
                    <li className="list-group-item"> السعر الحالي {summary.current.price} فلس في تاريخ {summary.current.date} حيث بلغ سعر التنكه (٢٠ لتر) <PriceDisplay price={summary.current.price * 20}/> وذلك انعكس على سعر التنكه {summary.current.price > summary.previous.price? 'برفعها': 'بخفضها'} <PriceDisplay price={Math.abs(summary.current.price - summary.previous.price) * 20}/> </li>
                    <li className="list-group-item">الفرق بين أقل و أعلى سعر هو {Math.abs(summary.max.price - summary.min.price)} فلس و بنسبة {((summary.min.price / summary.max.price) * 100).toFixed(1) + '%'} </li>
                </ul>
            </div>
        </>
    )
}

export default Summary;