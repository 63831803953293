import {Link} from "react-router-dom";
import Links from "./misc/Links";

const Home = function () {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title"> احتساب فاتورة الكهرباء</h5>
                    <p className="card-text">يمكنك احتساب فاتورة الكهرباء المنزلية لمعرفة الفرق بين التعرفة القديمة
                        والجديدة٫ كل ما عليك إدخال كمية الإستهلاك "الكمية المفوترة" الموجودة على فاتورتك.
                    </p>
                    <Link to="electricity" className="btn btn-primary">احتساب</Link>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ddc.electricity"><img
                        style={{height: 60}} src="/play.png"/></a>
                </div>
            </div>
            {/*<div className="card mt-3">*/}
            {/*    <div className="card-body">*/}
            {/*        <h5 className="card-title"> احتساب فاتورة المياه</h5>*/}
            {/*        <p className="card-text">احسب ضريبة الدخل الخاصة بك باستخدام الحاسبة الالكترونية بناء على تعديلات*/}
            {/*            قانون ضريبة الدخل لعام 2019</p>*/}
            {/*        <Link to="water" className="btn btn-primary">احتساب</Link>*/}
            {/*        /!*<a target="_blank" href="https://play.google.com/store/apps/details?id=com.ddc.incometax"><img style={{height: 60}} src="/play.png"/></a>*!/*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="card mt-3">
                <div className="card-body">
                    <h5 className="card-title"> احتساب ضريبة الدخل للمكلفين الأفراد</h5>
                    <p className="card-text">احسب ضريبة الدخل الخاصة بك باستخدام الحاسبة الالكترونية بناء على تعديلات
                        قانون ضريبة الدخل لعام 2019</p>
                    <Link to="income-tax" className="btn btn-primary">احتساب</Link>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ddc.incometax"><img
                        style={{height: 60}} src="/play.png"/></a>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h5 className="card-title">أسعار المحروقات في الأردن</h5>
                    <p className="card-text">يمكنك تتبع ومقارنة أسعار المحروقات في الأردن منذ ٢٠٠٨ و حتى الآن</p>
                    <Link to="oil-prices" className="btn btn-primary">مشاهدة</Link>
                </div>
            </div>
            <Links/>
        </>
    )
}

export default Home;