const Links = function () {
    return (
        <div className="card mt-3">
            <div className="card-header">
                روابط مفيدة
            </div>
            <div className="card-body">
                <ul>
                    <li className="p-1">
                        <a target="_blank"
                           href="https://istd.gov.jo/ebv4.0/root_storage/ar/eb_list_page/%D9%82%D8%A7%D9%86%D9%88%D9%86_%D9%85%D8%B9%D8%AF%D9%84_%D9%84%D9%82%D8%A7%D9%86%D9%88%D9%86_%D8%B6%D8%B1%D9%8A%D8%A8%D8%A9_%D8%A7%D9%84%D8%AF%D8%AE%D9%84_%D8%B1%D9%82%D9%85_38_%D9%84%D8%B3%D9%86%D8%A9_2018.pdf">قانون
                            معدل لقانون ضريبة الدخل رقم 38 لسنة 2018 (الفترات 2019 وما بعد).
                        </a>
                    </li>
                    <li className="p-1">
                        <a href="https://etax.istd.gov.jo/Login.aspx" target="_blank">
                            الخدمات الالكترونية الخاصة بدائرة ضريبة الدخل والمبيعات.
                        </a>
                    </li>
                    <li className="p-1">
                        <a href="https://kahraba.gov.jo/">
                            اضغط هنا للدخول إلى منصة الاستفادة من التعرفة الكهربائية المدعومة.
                        </a>
                    </li>
                    <li className="p-1">
                        <a href="https://emrc.gov.jo/DetailsPage/NewsDetails?ID=123#:~:text=%D9%88%D8%AD%D9%88%D9%84%20%D8%AA%D9%81%D8%A7%D8%B5%D9%8A%D9%84%20%D8%A3%D8%B3%D8%B9%D8%A7%D8%B1%20%D8%A7%D9%84%D8%B4%D8%B1%D8%A7%D8%A6%D8%AD%20%D9%84%D9%84%D8%AA%D8%B9%D8%B1%D9%81%D8%A9,%D9%81%D9%84%D8%B3%20%D9%84%D9%83%D9%84%20%D9%83%D9%8A%D9%84%D9%88%20%D9%88%D8%A7%D8%B7%20%D8%B3%D8%A7%D8%B9%D8%A9).">
                            تعديل التعرفة الكهربائية - هيئة تنظيم قطاع الطاقة و المعادن
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Links;