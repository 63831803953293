import {useEffect, useState} from "react";
import TaxUtil from "./util/TaxUtil";

const exemptions = [
    {value: "medical", label: 'نفقات علاج'},
    {value: "education", label: 'نفقات تعليم'},
    {value: "rent", label: 'إيجار'},
    {value: "loans", label: 'فوائد قروض الإسكان والمرابحة'}
]


const TaxExemption = function ({records, setRecords, supportedTypes, disabilities, setDisabilities, individual = false}) {

    const add = function () {
        const result = [...records];
        result.push({type: "husband", exemption: "medical", amount: 0});
        setRecords(result);
    }

    const remove = function (index) {
        const result = [...records];
        result.splice(index, 1);
        setRecords(result);
    }

    return (
        <>
            <table className="table bg-white">
                <thead>
                <tr>
                    <th>الطرف</th>
                    <th>البند</th>
                    <th colSpan="2">المبلغ المدفوع</th>
                </tr>
                </thead>
                <tbody>
                {records.map((r, index) =>
                    <>
                        <ExemptionRecord key={index} records={records} setRecords={setRecords} index={index} add={add}
                                         remove={remove} supportedTypes={supportedTypes}/>
                    </>
                )}
                <tr>
                    {/*<td colSpan={records.length === 0? 3: 3}></td>*/}
                    <td className="text-center" colSpan="4">
                        <button className="btn btn-primary ms-1" onClick={() => add()} style={{width: 100}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            {
                !individual &&
                <div>
                    <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label">عدد الأشخاص من ذوي الإعاقة المستمرة في الاسرة</label>
                        <div className="col-sm-8">
                            <input type="number" className="form-control" value={disabilities} onChange={e => setDisabilities(e.target.value)}/>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const ExemptionRecord = function ({records, setRecords, index, add, remove, supportedTypes}) {
    const [typeValue, setTypeValue] = useState(records[index].type);
    const [exemptionValue, setExemptionValue] = useState(records[index].exemption);
    const [amountValue, setAmountValue] = useState(records[index].amount);

    useEffect(() => {
        setTypeValue(records[index].type);
        setExemptionValue(records[index].exemption);
        setAmountValue(records[index].amount);
    }, [records]);

    const setType = function (value) {
        const result = [...records];
        result[index] = {type: value, exemption: exemptionValue, amount: amountValue}
        setRecords(result);
        setTypeValue(value);
    }

    const setExemption = function (value) {
        const result = [...records];
        result[index] = {type: typeValue, exemption: value, amount: amountValue}
        setRecords(result);
        setExemptionValue(value);
    }

    const setAmount = function (value) {
        const result = [...records];
        result[index] = {type: typeValue, exemption: exemptionValue, amount: parseInt(value)}
        setRecords(result);
        setAmountValue(parseInt(value));
    }
    const lonely = records.length === 1;

    const types = TaxUtil.partyType.filter(t => supportedTypes.indexOf(t.value) > -1);

    return (
        <tr>
            <td>
                {types.length === 1 ?
                    <div className="py-1">{TaxUtil.partyType[0].label}</div>
                    :
                    <select name="type" className="form-select" value={typeValue}
                            onChange={(e) => setType(e.target.value)}>
                        {types.map((o, index) =>
                            <option key={index} value={o.value}>{o.label}</option>
                        )}
                    </select>
                }
            </td>
            <td>
                <select name="exemption" className="form-select" value={exemptionValue}
                        onChange={(e) => setExemption(e.target.value)}>
                    {exemptions.map((o, index) =>
                        <option key={index} value={o.value}>{o.label}</option>
                    )}
                </select></td>
            <td>
                <input type="number" className="form-control" value={amountValue}
                       onChange={(e) => setAmount(e.target.value)}/>
            </td>
            <td>
                <div className="d-flex">
                    <button className="btn btn-danger w-100" onClick={() => remove(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
                        </svg>
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default TaxExemption;