import PriceDisplay from "../util/PriceDisplay";

const Levels = function ({levels}) {
    if (!levels) {
        return <></>
    }

    return (
        <table className="table-bordered table bg-white shadow-sm">
            <thead>
            <tr>
                <th>من</th>
                <th>إلى</th>
                <th>فلس/(ك.و.س)</th>
                <th>الكمية</th>
                <th>السعر</th>
            </tr>
            </thead>
            <tbody>
            {levels.map((l, index) =>
                <tr key={index}>
                    <td>{l.start}</td>
                    <td>{l.end !== -1 ? l.end : ""}</td>
                    <td>{l.rate}</td>
                    <td>{l.amount}</td>
                    <td><PriceDisplay price={l.price} currency={false}/></td>
                </tr>
            )}
            </tbody>
        </table>
    )
}

export default Levels;