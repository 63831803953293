import TaxCalculator from "./TaxCalculator";
import {Helmet} from "react-helmet";

const IncomeTax = function () {
    return (
        <div>
            <Helmet>
                <title>
                    احتساب ضريبة الدخل للمكلفين الأفراد
                </title>
            </Helmet>
            <div className="pb-3 text-center">
                <h2>احتساب ضريبة الدخل للمكلفين الأفراد</h2>
                <p className="text-center"><a href="https://etax.istd.gov.jo/Login.aspx" target="_blank">اضغط هنا للدخول إلى الخدمات الالكترونية الخاصة بدائرة ضريبة الدخل والمبيعات</a></p>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ddc.incometax"><img style={{height: 60}} src="/play.png"/></a>
            </div>

            <div className="row">
                <div className="col">
                    <TaxCalculator/>
                </div>
            </div>
        </div>
    )
};

export default IncomeTax;