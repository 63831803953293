import PriceDisplay from "../util/PriceDisplay";

const Levels = function ({tax}) {
    if (!tax) {
        return <></>
    }

    return (
        <table className="table-bordered table bg-white shadow-sm">
            <thead>
            <tr>
                <th>من</th>
                <th>إلى</th>
                <th>النسبة</th>
                <th>الدخل الخاضع</th>
                <th>الضريبة المستحقة</th>
            </tr>
            </thead>
            <tbody>
            {tax.levels.map((l, index) =>
                <tr key={index}>
                    <td>{l.start}</td>
                    <td>{l.end !== -1 ? l.end : ""}</td>
                    <td>{l.rate}%</td>
                    <td>{l.amount? l.amount: 0}</td>
                    <td><PriceDisplay price={l.price} currency={false}/></td>
                </tr>
            )}
            <tr className="table-primary">
                <td colSpan="2">الضريبة المستحقة</td>
                <td colSpan="3"><PriceDisplay price={tax.total} currency={true}/></td>
            </tr>
            </tbody>
        </table>
    )
}

export default Levels;