import PriceDisplay from "../util/PriceDisplay";

const SummaryGas = function({data}) {

    const calculate = function(data) {

        let max;
        let min;
        const current = data.values[data.values.length - 1];
        const previous = data.values[data.values.length - 2];

        data.values.forEach(i => {
            if (!max || max.price < i.price) {
                max = i;
            }

            if (!min || min.price > i.price) {
                min = i;
            }
            console.log(i);
        });

        return {
            max,
            min,
            previous,
            current
        }
    }

    const summary = calculate(data);

    console.log(summary);

    return (
        <div className="card py-3 px-2 shadow-sm">
            <ul className="list-group list-group-flush">
                <li className="list-group-item">السعر الحالي <PriceDisplay price={summary.current.price}/> </li>
                <li className="list-group-item">آخر تعديل كان بتاريخ {summary.current.date} حيث تم { summary.previous.price > summary.current.price? 'تخفيض': 'رفع'} السعر ب {Math.abs(summary.previous.price - summary.current.price)} فلس و بنسبة {((summary.previous.price > summary.current.price? 1 - summary.current.price / summary.previous.price: 1- summary.previous.price / summary.current.price) * 100).toFixed(1) + "%"}</li>
                <li className="list-group-item">أعلى سعر للأسطوانة بلغ <PriceDisplay price={summary.max.price}/> في تاريخ {summary.max.date} </li>
                <li className="list-group-item">أقل سعر للأسطوانة بلغ <PriceDisplay price={summary.min.price}/> في تاريخ {summary.min.date} </li>
                <li className="list-group-item">الفرق بين أقل و أعلى سعر هو <PriceDisplay price={Math.abs(summary.max.price - summary.min.price)} /> و بنسبة {((summary.min.price / summary.max.price) * 100).toFixed(1) + '%'} </li>
            </ul>
        </div>
    )
}

export default SummaryGas;