import TaxUtil from "./util/TaxUtil";

const ExemptionSummary = function ({exemptions, disabilities}) {

    return (
        <table className="table-bordered table bg-white shadow-sm">
            <thead>
            <tr>
                <th>الطرف</th>
                <th>إجمالي المدفوع</th>
                <th>الحد الأعلى للإعفاء</th>
                <th>المبلغ المعفي</th>
            </tr>
            </thead>
            <tbody>
            {exemptions.limits.map((e, index) =>
                e.sum > 0 &&
                <tr key={index}>
                    <td>
                        {TaxUtil.getPartyDisplay(e.value)}
                    </td>
                    <td>
                        {e.sum}
                    </td>
                    <td>
                        {e.limit}
                    </td>
                    <td>
                        {e.max}
                    </td>
                </tr>
            )}
            {disabilities > 0 &&
            <tr>
                <td colSpan={3}>
                    إعفاء الأشخاص من ذوي الإعاقة المستمرة في الاسرة
                </td>
                <td>
                    {disabilities * 2000}
                </td>
            </tr>
            }
            <tr className="table-primary">
                <td colSpan={3}>إجمالي المبلغ المعفي</td>
                <td>{exemptions.total + (disabilities * 2000)}</td>
            </tr>
            </tbody>
        </table>
    )
}

export default ExemptionSummary;