import PriceDisplay from "../util/PriceDisplay";

const Modifications = function ({data}) {
    return (
        <div className="card shadow-sm p-4">
            <table className="table">
                <thead>
                <th>تاريخ التعديل</th>
                <th>نسبة التعديل</th>
                <th>فرق السعر بالفلس</th>
                <th>سعر الليتر بالفلس</th>
                <th>سعر التنكة بالدينار الأردني</th>
                </thead>
                <tbody>
                { data.values.map((v, i) =>
                    <Row data={data.values} value={v} index={i}/>
                )}
                </tbody>
            </table>
        </div>
    )
}

const Row = function ({data, value, index}) {

    const percentage = function(data, value, index) {
        if (index - 1 < 0 || index -1 > data.length - 1) {
            return <></>
        }

        const previous = data[index - 1];

        return ((
                previous.price > value.price?
                    1 - value.price / previous.price:
                    1 - previous.price / value.price
            ) * 100
        ).toFixed(1) + "%";
    }

    const increase = function (data, value, index) {
        if (index - 1 < 0 || index -1 > data.length - 1) {
            return false;
        }

        const previous = data[index - 1];

        return previous.price < value.price;
    }

    const difference = function(data, value, index) {
        if (index - 1 < 0 || index -1 > data.length - 1) {
            return false;
        }

        const previous = data[index - 1];

        return Math.abs(previous.price - value.price)
    }

    return (
        <tr>
            <td>{value.date}</td>
            <td>{index !== 0 && (increase(data, value, index)?
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-caret-up-fill text-danger" viewBox="0 0 16 16">
                    <path
                        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-caret-down-fill text-success" viewBox="0 0 16 16">
                    <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            )}
                {percentage(data, value, index)}
            </td>
            <td>{difference(data, value, index)}</td>
            <td>{value.price}</td>
            <td><PriceDisplay price={value.price * 20} currency={false}/></td>
        </tr>
    )
}

export default Modifications;