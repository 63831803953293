const Header = function () {
    return (
        <div className="py-3 text-center">
            <a href="/">
                <img className="d-block mx-auto mb-4" src="/logo192.webp" alt="" width="72"
                     height="57"/>
            </a>
        </div>
    )
}

export default Header;