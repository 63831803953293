import PriceDisplay from "../util/PriceDisplay";

const Summary = function ({husbandTax, wifeTax, unionTax}) {

    const husbandWife = husbandTax.total + wifeTax.total;
    const unionMinus = unionTax.total - husbandWife;
    const minusUnion = husbandWife - unionTax.total;
    const isUnionBetter = unionTax.total < husbandWife;

    return (
        <table className="table-bordered table bg-white shadow-sm">
            <thead>
            <tr>
                <th>الطرف</th>
                <th>الدخل</th>
                <th>الضريبة المستحقة</th>
            </tr>
            </thead>
            <tbody>
            <tr className={!isUnionBetter && Math.abs(minusUnion) ? "table-success" : ""}>
                <td>المكلف</td>
                <td>{husbandTax.income}</td>
                <td><PriceDisplay price={husbandTax.total} currency={true}/></td>
            </tr>
            <tr className={!isUnionBetter && Math.abs(minusUnion) ? "table-success" : ""}>
                <td>زوج المكلف</td>
                <td>{wifeTax.income}</td>
                <td><PriceDisplay price={wifeTax.total} currency={true}/></td>
            </tr>
            <tr className={isUnionBetter && Math.abs(unionMinus) ? "table-success" : ""}>
                <td>الدخل المدمج</td>
                <td>{parseFloat(husbandTax.income) + parseFloat(wifeTax.income)}</td>
                <td><PriceDisplay price={unionTax.total} currency={true}/></td>
            </tr>
            {(husbandTax.total + wifeTax.total - unionTax.total) !== 0 &&
            <tr className="table-primary">
                <td colSpan={3} className="text-center">
                    {isUnionBetter ?
                        <>ينصح بدمج الدخل لتوفير <PriceDisplay
                            price={(minusUnion)}/></>
                        :
                        <>ينصح بعدم دمج الدخل، لتوفير <PriceDisplay
                            price={(unionMinus)}/></>
                    }
                </td>
            </tr>
            }
            </tbody>
        </table>
    )
}

export default Summary;