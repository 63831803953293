import Individual from "./Individual";
import Family from "./Family";
import {NavLink, Route, Routes} from "react-router-dom";


const TaxCalculator = function () {

    return (
        <div className="mx-auto">
            <div className="w-100">
                <div className="card p-1  shadow-sm">
                    <ul className="nav nav-pills nav-justified p-0">
                        <li className="nav-item">
                            <NavLink to="" end className="nav-link">المكلف الاعزب
                            </NavLink>
                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink to="family" end className="nav-link" id="pills-profile-tab">المكلف المتزوج/المعيل
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-3" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel">
                         <Routes>
                             <Route index element={
                                 <Individual/>
                             }/>
                             <Route path="family" element={
                                 <Family/>
                             }/>
                         </Routes>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TaxCalculator;