const TaxUtil = function () {

    this.singleTier = () => [
        {start: 0, end: 9000, rate: 0},
        {start: 9001, end: 14000, rate: 5},
        {start: 14001, end: 19000, rate: 10},
        {start: 19001, end: 24000, rate: 15},
        {start: 24001, end: 29000, rate: 20},
        {start: 29001, end: -1, rate: 25},
    ];

    this.coupleTier = () => [
        {start: 0, end: 18000, rate: 0},
        {start: 18001, end: 23000, rate: 5},
        {start: 23001, end: 28000, rate: 10},
        {start: 28001, end: 33000, rate: 15},
        {start: 33001, end: 38000, rate: 20},
        {start: 38001, end: -1, rate: 25},
    ];

    this.exemptionLimits = () => [
        {value: "husband", limit: 1000, sum: 0, max: 0},
        {value: "wife", limit: 1000, sum: 0, max: 0},
        {value: "child1", limit: 1000, sum: 0, max: 0},
        {value: "child2", limit: 1000, sum: 0, max: 0},
        {value: "child3", limit: 1000, sum: 0, max: 0},
        {value: "child3", limit: 1000, sum: 0, max: 0},
    ];

    this.partyType = [
        {value: "husband", label: 'المكلف'},
        {value: "wife", label: 'زوج المكلف'},
        {value: "child1", label: 'الطفل الأول'},
        {value: "child2", label: 'الطفل الثاني'},
        {value: "child3", label: 'الطفل الثالث'},
    ];

    this.getPartyDisplay = function (partyType) {
        return this.partyType.find(t => t.value === partyType).label;
    }


    this.calculateExemptions = function (records) {
        const limits = this.exemptionLimits();
        // sum of exemptions
        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            let limit = limits.find(l => l.value === record.type);

            limit.sum += record.amount ? record.amount : 0;
        }

        let total = 0;


        for (let i = 0; i < limits.length; i++) {
            const limit = limits[i];

            limit.max = Math.min(Math.max(0, limit.sum), limit.limit);
            total += limit.max
        }
        return {total, limits};
    }

    this.calculate = function (income, tier = [], records = [], disabilities = 0) {
        const result = {
            levels: [...tier],
            income: income,
            total: 0
        };
        income -= Math.max(this.calculateExemptions(records).total, 0);
        income -= disabilities * 2000

        for (let i = 0; i < result.levels.length; i++) {
            const level = result.levels[i];
            if (income > level.start) {
                let amount = (income - ((i === 0) ? 0 : i === result.levels.length - 1 ? level.start : level.start - 1));
                if (income > level.end && level.end !== -1) {
                    amount = level.end - Math.max(0, level.start - 1);
                }
                level.amount = amount;
                level.price = (amount * (level.rate / 100)) * 1000;
                result.total = Math.max(0, result.total + level.price);
            }
        }

        return result;
    }
}

export default new TaxUtil();