import {useCallback, useEffect, useState} from "react";
import {NavLink, useSearchParams} from "react-router-dom";

const Navbar = function() {
    const [display, setDisplay] = useState();
    const [searchParams] = useSearchParams();

    const handleClick = useCallback(() => {
        if (display) {
            setDisplay(false);
        }
    }, [display]);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    if (searchParams.get("hideHeader")) {
        return <></>
    }

    return (
        <nav className="navbar navbar-light bg-white shadow-sm fixed-top">
            <div className="container-fluid">
                <NavLink end className="navbar-brand" to=""><img src="/logo-small.webp" height="30"/> Jordan Tools </NavLink>
                <button className="navbar-toggler collapsed" type="button" onClick={() => setDisplay(!display)}>
                    <small><span className="navbar-toggler-icon"/></small>
                </button>

                { display &&
                <div className="navbar-collapse collapse d-block">
                    <ul className="navbar-nav me-auto mb-2">
                        <li key={1} className="nav-item">
                            <NavLink className="nav-link" to="/">الصفحة الرئيسية</NavLink>
                        </li>
                        <li key={2} className="nav-item">
                            <NavLink className="nav-link" to="electricity">احتساب فاتورة الكهرباء</NavLink>
                        </li>
                        <li key={3} className="nav-item">
                            <NavLink className="nav-link" to="income-tax">احتساب ضريبة الدخل للمكلفين الأفراد</NavLink>
                        </li>
                        <li key={4} className="nav-item">
                            <NavLink className="nav-link" to="oil-prices">أسعار المحروقات في الأردن</NavLink>
                        </li>
                        <li key={4} className="nav-item">
                            <NavLink className="nav-link" to="privacy">سياسة الخصوصية</NavLink>
                        </li>
                        <li key={5} className="nav-item">
                            <NavLink className="nav-link" to="links">روابط مفيدة</NavLink>
                        </li>
                    </ul>
                </div>
                }
            </div>
        </nav>
    )
}

export default Navbar;