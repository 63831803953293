const Links = function () {
    return (
        <div className="card mt-3">
            <div className="card-header">
                سياسة الخصوصية
            </div>
            <div className="card-body">
                شكرًا لزيارتكم لموقعنا الثابت. في هذه السياسة، نوضح كيفية التعامل مع المعلومات التي نتلقاها أثناء استخدامكم للموقع. نحن نهتم بحماية خصوصيتكم ونود توضيح كيفية جمع واستخدام وحماية المعلومات التي تقدمونها أثناء تصفحكم للموقع.


                <ul>
                    <li>
                        جمع المعلومات:
                        نود التنويه إلى أننا لا نقوم بجمع أي معلومات شخصية عند استخدامكم للموقع الثابت. لا نستخدم تقنيات التتبع مثل ملفات تعريف الارتباط (الكوكيز) أو أدوات التحليل لجمع معلومات عن الزوار.

                    </li>
                    <li>
                        ملفات تعريف الارتباط:
                        لم يتم استخدام ملفات تعريف الارتباط على موقعنا. يمكنكم تعطيل ملفات تعريف الارتباط في إعدادات المتصفح الخاص بكم إذا كنتم تفضلون عدم استخدامها أثناء تصفح الموقع.
                    </li>
                    <li>
                        تغييرات على سياسة الخصوصية:
                        قد نقوم بتحديث هذه السياسة من وقت لآخر بناءً على تغييرات في المتطلبات القانونية أو التغييرات في طريقة تشغيل الموقع. سيتم نشر أي تغييرات على هذه الصفحة وستكون سارية المفعول فور نشرها. ننصحكم بالتحقق من هذه الصفحة بشكل دوري لمتابعة أي تحديثات.
                    </li>
                    <li>
                        الاتصال بنا:
                        إذا كان لديكم أي استفسارات حول سياسة الخصوصية أو كيفية تعاملنا مع المعلومات على الموقع، يمكنكم التواصل معنا عبر البريد الإلكتروني المقدم على الموقع.

                        نرجو منكم قراءة سياسة الخصوصية بعناية وفهم مدى التزامنا بحماية خصوصيتكم أثناء استخدامكم للموقع. توضح هذه السياسة كيفية التعامل مع المعلومات والبيانات، على الرغم من أننا لا نقوم بجمع أي معلومات شخصية من زوار الموقع الثابت.
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default Links;