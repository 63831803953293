const PriceDisplay = function ({price = 0, currency = true}) {
    price = price / 1000.0;
    let tokens = (price + "").split(".");
    let integerPrice = tokens[0];

    let decimal = "00"
    if (tokens.length > 1) {
        decimal = tokens[1];
    }

    if (decimal.length === 2) {
        decimal += "0";
    } else if (decimal.length === 1) {
        decimal += "00";
    } else if (decimal.length === 0) {
        decimal = "000";
    }

    if (decimal.length > 3) {
        decimal = decimal.substr(0, 3);
    }
    return (
        <>
            <strong>{integerPrice}.{decimal}</strong>
            {currency && <span className="pe-1">دينار أردني </span>}
        </>
    )
}

export default PriceDisplay;