import {Route, Routes, Switch, useParams} from "react-router-dom";
import BillCalculator from "./BillCalculator";
import {Helmet} from "react-helmet";

const Electricity = function () {
    return (
        <Routes>
            <Route path="bill/:amount" element={<Main/>}/>
            <Route index element={<Main/>}/>
        </Routes>
    )
}

const Main = function () {

    const params = useParams();

    return (
        <div>
            <Helmet>
                <title>
                    احتساب فاتورة الكهرباء في الأردن
                </title>
            </Helmet>
            <div className="text-center">
                <h2>احتساب فاتورة الكهرباء في الأردن</h2>
                <p className="lead">يمكنك احتساب فاتورة الكهرباء المنزلية لمعرفة الفرق بين التعرفة القديمة والجديدة٫ كل
                    ما عليك إدخال كمية الإستهلاك "الكمية المفوترة" الموجودة على فاتورتك.</p>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ddc.electricity"><img style={{height: 60}} src="/play.png"/></a>
            </div>
            <p className="text-center">ملاحظة: تم تحديث الموقع لحساب التعرفة غير المدعومة.</p>
            <p className="text-center"><a href="https://kahraba.gov.jo/" target="_blank">اضغط هنا للدخول إلى منصة
                الاستفادة من التعرفة الكهربائية المدعومة</a></p>
            <div className="row">
                <div className="col">
                    <BillCalculator amount={params.amount}/>
                </div>
            </div>
        </div>
    );
}

export default Electricity;