import {useState} from "react";
import Levels from "./Levels";
import TaxUtil from "./util/TaxUtil";
import Summary from "./Summary";
import TaxExemption from "./TaxExemption";
import ExemptionSummary from "./ExemptionSummary";


const Family = function () {
    const [husbandIncome, setHusbandIncome] = useState(0);
    const [wifeIncome, setWifeIncome] = useState(0);
    const [husbandTax, setHusbandTax] = useState(0);
    const [wifeTax, setWifeTax] = useState(0);
    const [unionTax, setUnionTax] = useState(0);
    const [records, setRecords] = useState([]);
    const [exemptions, setExemptions] = useState([]);
    const [disabilities, setDisabilities] = useState(0);

    const calculate = function () {
        if (husbandIncome > 0 || wifeIncome > 0) {
            setHusbandTax(TaxUtil.calculate(husbandIncome, TaxUtil.singleTier(), records.filter(r => r.type !== "wife"), disabilities));
            setWifeTax(TaxUtil.calculate(wifeIncome, TaxUtil.singleTier(), records.filter(r => r.type === "wife")));
            setUnionTax(TaxUtil.calculate(parseFloat(husbandIncome) + parseFloat(wifeIncome), TaxUtil.coupleTier(), records, disabilities));
            setExemptions(TaxUtil.calculateExemptions(records));
        }
    }

    const toggleExemeptions = function () {
        if (records.length === 0) {
            setRecords([{type: "husband", exemption: "medical", amount: 0}]);
            setExemptions([]);
        } else {
            setRecords([]);
            setExemptions([]);
        }
    }

    const clear = function() {
        setRecords([]);
        setExemptions([]);
        setHusbandIncome(0);
        setWifeIncome(0);
        setHusbandTax(0);
        setWifeTax(0);
        setUnionTax(0);
        setDisabilities(0);
    }

    return (
        <>
            <div className="card p-3 shadow-sm">
                <div className="mx-auto w-100">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <label className="form-label col-md-3 py-1">دخل المكلف السنوي</label>
                                <div className="col-md-9">
                                    <input type="number" className="form-control" placeholder="دخل المكلف السنوي"
                                           value={husbandIncome}
                                           onChange={(e) => setHusbandIncome(e.target.value || 0)} onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            calculate();
                                        }
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <label className="form-label col-md-4 py-1">الإعفاء الشخصي</label>
                                <div className="col-md-8">
                                    <input type="number" className="form-control" placeholder="دخل الزوجة" value={9000} disabled={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <div className="row">
                                <label className="form-label col-md-3 py-1">دخل زوج المكلف السنوي</label>
                                <div className="col-md-9">
                                    <input type="number" className="form-control" placeholder="دخل زوج المكلف السنوي"
                                           value={wifeIncome}
                                           onChange={(e) => setWifeIncome(e.target.value || 0)} onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            calculate();
                                        }
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <label className="form-label col-md-4 py-1">الإعفاء الشخصي</label>
                                <div className="col-md-8">
                                    <input type="number" className="form-control" placeholder="دخل الزوجة" value={9000} disabled={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <span className="link-primary" onClick={toggleExemeptions}>{
                            records.length > 0 ?
                                <>إلغاء الإعفاءات</>
                                :
                                <> إضافة الإعفاءات</>
                        }</span>
                    </div>
                    {
                        records.length > 0 &&
                        <div className="row mx-auto mt-4">
                            <TaxExemption records={records} setRecords={setRecords}
                                          supportedTypes={["husband", "wife", "child1", "child2", "child3"]}
                                          disabilities={disabilities} setDisabilities={setDisabilities}/>
                        </div>
                    }
                </div>

                <div className="mt-4 row">
                    <div className="col-md-6 py-1">
                        <button className="btn btn-primary w-100" onClick={calculate} disabled={husbandIncome === 0 && wifeIncome === 0}>احتساب</button>
                    </div>
                    <div className="col-md-6 py-1">
                        <button className="btn btn-danger w-100" onClick={clear}>تفريغ الحقول</button>
                    </div>
                </div>
            </div>

            {
                exemptions.total > 0 &&
                <div className="mt-4">
                    <h4>ملخص الإعفاءات</h4>
                    <ExemptionSummary exemptions={exemptions} disabilities={disabilities}/>
                </div>
            }

            {husbandTax?.levels &&
            <>
                <div className="mt-5">
                    <Summary husbandIncome={husbandIncome} wifeIncome={wifeIncome} husbandTax={husbandTax}
                             wifeTax={wifeTax} unionTax={unionTax}/>
                </div>
                <div className="mt-5">
                    <div className="row">
                        <div className="col">
                            <h5>الشرائح الضريبية للمكلف</h5>
                            <Levels tax={husbandTax}/>
                        </div>

                        <div className="col">
                            <h5>الشرائح الضريبية لزوج المكلف</h5>
                            <Levels tax={wifeTax}/>
                        </div>
                        <div className="col">
                            <h5>الشرائح الضريبية للعائلة (دمج الدخل)</h5>
                            <Levels tax={unionTax}/>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default Family;