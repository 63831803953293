import PriceDisplay from "../util/PriceDisplay";
import {useSearchParams} from "react-router-dom";

const PreviousReadings = function () {

    const [searchParams] = useSearchParams();

    const queryString = searchParams.toString();

    let readings = window.localStorage.getItem("readings2");

    if (readings) {
        readings = JSON.parse(readings);
    }

    if (!readings || readings.length === 0) {
        return <></>
    }

    const moreAided = function(reading) {
        return reading.oldTotal === reading.newTotal ? 0 : reading.oldTotal > reading.newTotal ? -1 : 1
    }

    const more = function(reading) {
        return reading.oldTotal === reading.notAidedTotal ? 0 : reading.oldTotal > reading.notAidedTotal ? -1 : 1
    }

    return (
        <table className="table table-bordered bg-white shadow-sm my-4">
            <thead>
            <tr>
                <th className="text-center" colSpan="4">احتسابات سابقة</th>
            </tr>
            <tr>
                <th>كمية الاستهلاك (ك.و.س)</th>
                <th>التعرفة القديمة</th>
                <th>التعرفة الجديدة المدعومة<small className="sub align-top">(2022)</small></th>
                <th>التعرفة الجديدة غير المدعومة<small className="sub align-top">(2022)</small></th>
            </tr>
            </thead>
            <tbody>
            {readings.map((r, index) =>
                <tr key={index}>
                    <td>
                        <a href={"/electricity/bill/" + (r.value || 0) + (queryString? "?" + queryString : "")}><h6 className="my-0">{r.value}</h6></a>
                    </td>
                    <td><PriceDisplay price={r.oldTotal}/></td>
                    <td className={moreAided(r) === -1 ? "text-success" : more(r) === 1 ? "text-danger" : ""}><PriceDisplay price={r.newTotal}/></td>
                    <td className={more(r) === -1 ? "text-success" : more(r) === 1 ? "text-danger" : ""}><PriceDisplay price={r.notAidedTotal}/></td>
                </tr>
            )}
            </tbody>
        </table>
    )
}

export default PreviousReadings;