import {Line} from 'react-chartjs-2';
import NaturalGas from './natural_gas.json';
import Petrol90 from './petrol90.json';
import Petrol95 from './petrol95.json';
import Kerosene from './kerosene.json';
import Diesel from './diesel.json';

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {NavLink, Route, Routes} from "react-router-dom";
import Summary from "./Summary";
import SummaryGas from "./SummaryGas";
import {Helmet} from "react-helmet";
import Modifications from "./Modifications";
import ModificationsGas from "./ModificationsGas";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const buildData = function (data) {
    console.log(data);
    var result = {
        label: data.label,
        data: data.values.map(v => v.price),
        borderColor: data.color,
        backgroundColor: data.color,
    }
    console.log(result);
    return result;
}

const buildLabels = function (data) {
    console.log(data);
    const labels = [];

    data.forEach(d => {
        d.values.forEach(v => {
            if (labels.indexOf(v.date) < 0) {
                labels.push(v.date);
            }
        })
    });

    console.log("Labels");
    console.log(labels);
    return labels.sort();
}

const OilPrices = function () {

    const petrol90 = {
        labels: buildLabels([Petrol90]),
        datasets: [
            buildData(Petrol90)
        ],
    }

    const petrol95 = {
        labels: buildLabels([Petrol95]),
        datasets: [
            buildData(Petrol95)
        ],
    }

    const naturalGas = {
        labels: buildLabels([NaturalGas]),
        datasets: [
            buildData(NaturalGas)
        ],
    }

    const kerosene = {
        labels: buildLabels([Kerosene]),
        datasets: [
            buildData(Kerosene)
        ],
    }

    const diesel = {
        labels: buildLabels([Diesel]),
        datasets: [
            buildData(Diesel)
        ],
    }

    return (
        <>
            <Helmet>
                <title>
                    أسعار المحروقات في الأردن
                </title>
            </Helmet>
            <div className="pb-3 text-center">
                <h2>أسعار المحروقات في الأردن من ٢٠٠٨ و حتى الآن</h2>

            </div>
            <div className="card p-1  shadow-sm">
                <ul className="nav nav-pills nav-justified p-0">
                    <li className="nav-item">
                        <NavLink to="" end className="nav-link">بنزين 90
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="petrol-95" end className="nav-link">بنزين 95
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="natural-gas" end className="nav-link">اسطوانة الغاز المنزلي
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="kerosene" end className="nav-link">كاز
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="diesel" end className="nav-link">ديزل
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className="tab-content mt-3" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel">
                    <Routes>
                        <Route end path="" element={
                            <>
                                <div className="p-3 card shadow-sm">
                                    <Line data={petrol90}/>
                                </div>
                                <div className="mt-4">
                                    <Summary data={Petrol90}/>
                                </div>
                                <div className="mt-4">
                                    <Modifications data={Petrol90}/>
                                </div>
                            </>
                        }/>
                        <Route path="petrol-95" element={
                            <>
                                <div className="p-3 card shadow-sm">
                                    <Line data={petrol95}/>
                                </div>
                                <div className="mt-4">
                                    <Summary data={Petrol95}/>
                                </div>
                                <div className="mt-4">
                                    <Modifications data={Petrol95}/>
                                </div>
                            </>
                        }/>
                        <Route path="natural-gas" element={
                            <>
                                <div className="p-3 card shadow-sm">
                                    <Line data={naturalGas}/>
                                </div>
                                <div className="mt-4">
                                    <SummaryGas data={NaturalGas}/>
                                </div>
                                <div className="mt-4">
                                    <ModificationsGas data={NaturalGas}/>
                                </div>
                            </>
                        }/>
                        <Route path="kerosene" element={
                            <>
                                <>
                                    <div className="p-3 card shadow-sm">
                                        <Line data={kerosene}/>
                                    </div>
                                    <div className="mt-4">
                                        <Summary data={Kerosene}/>
                                    </div>
                                    <div className="mt-4">
                                        <Modifications data={Kerosene}/>
                                    </div>
                                </>
                            </>
                        }/>
                        <Route path="diesel" element={
                            <>
                                <div className="p-3 card">
                                    <Line data={diesel}/>
                                </div>
                                <div className="mt-4">
                                    <Summary data={Diesel}/>
                                </div>
                                <div className="mt-4">
                                    <Modifications data={Diesel}/>
                                </div>
                            </>
                        }/>
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default OilPrices;