import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Home from "./Home";
import Electricity from "./electricity/Electricity";
import IncomeTax from "./income-tax/IncomeTax";
import Header from "./layout/Header";
import Navbar from "./layout/Navbar";
import Links from "./misc/Links";
import OilPrices from "./oil-prices/OilPrices";
import Privacy from "./misc/Privacy";
import Water from "./water/Water";

// import {useEffect} from "react";

function App() {

    return (
        <Router>
            <MainComponent/>
        </Router>
    );
}

const MainComponent = function () {
    // const [searchParams] = useSearchParams();
    //
    // useEffect(() => {
    //
    //     if (searchParams.get("mobile") === "true") {
    //         return () => {
    //         };
    //     }
    //
    //     const script = document.createElement('script');
    //
    //     script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1351669499284281";
    //     script.async = true;
    //     script.crossOrigin = "anonymous"
    //
    //     document.body.appendChild(script);
    //
    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);

    return (
        <main>
            <Navbar/>
            <div className="container mt-5 py-4">
                <Header/>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="electricity/*" element={<Electricity/>}/>
                    <Route path="water/*" element={<Water/>}/>
                    <Route path="income-tax/*" element={<IncomeTax/>}/>
                    <Route path="oil-prices/*" element={<OilPrices/>}/>
                    <Route path="links/*" element={<Links/>}/>
                    <Route path="privacy/*" element={<Privacy/>}/>
                </Routes>
            </div>
        </main>
    )
}

export default App;
