import PriceDisplay from "../util/PriceDisplay";
import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

const oldValues = [
    {start: 0, end: 6, rate: 2.18, sewerage: 0.23},
    {start: 7, end: 12, rate: 0.45, sewerage: 0.06},
    {start: 13, end: 18, rate: 0.55, sewerage: 0.29},
    {start: 19, end: 24, rate: 1, sewerage: 0.57},
    {start: 25, end: 30, rate: 1.2, sewerage: 0.8},
    {start: 21, end: 42, rate: 1.62, sewerage: 0.93},
    {start: 42, end: -1, rate: 1.92, sewerage: 1.1},
];

const newValues = [
    {start: 0, end: 6, rate: 2.18, sewerage: 0.23},
    {start: 7, end: 12, rate: 0.50, sewerage: 0.09},
    {start: 13, end: 18, rate: 0.60, sewerage: 0.3},
    {start: 19, end: 24, rate: 1.05, sewerage: 0.6},
    {start: 25, end: 30, rate: 1.3, sewerage: 0.85},
    {start: 21, end: 42, rate: 1.8, sewerage: 0.95},
    {start: 42, end: -1, rate: 2.2, sewerage: 1.2},
];

const WaterBillCalculator = ({amount}) => {
    const [value, setValue] = useState(amount || "");
    const [oldPrices, setOldPrices] = useState({});
    const [newAidedPrices, setNewAidedPrices] = useState({});
    const [newPrices, setNewPrices] = useState({});

    const [searchParams] = useSearchParams();

    const moreSubtotalAided = null;
    const moreSubtotal = null;

    const navigate = useNavigate();

    const calculate = () => {
    }

    const reset = function () {
        setValue(0);
        calculate();
    }

    return (<div className="mx-auto">
        <div className="card p-2 shadow-sm">
            <div className="input-group">
                <input autoFocus type="number" className="form-control rounded-0" onKeyPress={event => {
                    if (event.key === 'Enter') {
                        calculate();
                    }
                }} placeholder="كمية الاستهلاك (م٣)" value={value} onChange={(e) => setValue(e.target.value)}/>
                <button style={{width: 80}} className="btn btn-primary rounded-0" type="button"
                        disabled={value === "" || value === 0} onClick={() => reset()}>مسح
                </button>
                <button style={{width: 80}} className="btn btn-success rounded-0" type="button"
                        disabled={value === "" || value === 0} onClick={() => calculate()}>احتساب
                </button>
            </div>
        </div>
        <table className="table table-bordered mt-4 shadow-sm bg-white">
            <thead>
            <tr>
                <th>البيان</th>
                <th>التعرفة القديمة</th>
                <th>التعرفة الجديدة<small className="sub align-top">(ابتداء من شهر ١٢-٢٠٢٣)</small></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>قيمة الإستهلاك</td>
                <td><PriceDisplay price={oldPrices.consumptionPrice} currency={false}/></td>
                <td><PriceDisplay price={newAidedPrices.consumptionPrice} currency={false}/></td>
            </tr>
            <tr>
                <td>تعرفة الصرف الصحي</td>
                <td><PriceDisplay price={oldPrices.gasPricesDiff} currency={false}/></td>
                <td><PriceDisplay price={newAidedPrices.gasPricesDiff} currency={false}/></td>
            </tr>
            <tr>
                <td>قيمة الفاتورة</td>
                <td><PriceDisplay price={oldPrices.total}/></td>
            </tr>
            </tbody>
        </table>
    </div>)
}

export default WaterBillCalculator;