import Levels from "./Levels";
import {useState} from "react";
import TaxUtil from "./util/TaxUtil";
import ExemptionSummary from "./ExemptionSummary";
import TaxExemption from "./TaxExemption";

const Individual = function () {
    const [income, setIncome] = useState(0);
    const [tax, setTax] = useState();
    const [records, setRecords] = useState([]);
    const [exemptions, setExemptions] = useState([]);

    const calculate = function () {
        if (income > 0) {
            setTax(TaxUtil.calculate(income, TaxUtil.singleTier(), records));
            setExemptions(TaxUtil.calculateExemptions(records));
        }
    }

    const toggleExemeptions = function () {
        if (records.length === 0) {
            setRecords([{type: "husband", exemption: "medical", amount: 0}]);
            setExemptions([]);
        } else {
            setRecords([]);
            setExemptions([]);
        }
    }

    const clear = function() {
        setRecords([]);
        setExemptions([]);
        setTax();
        setIncome(0);
    }

    return (
        <>
            <div className="card p-3 shadow-sm">
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <label htmlFor="firstName" className="col-md-3 form-label py-1">دخل المكلف السنوي</label>
                                <div className="col-md-9">
                                    <input type="number" className="form-control" placeholder="" value={income}
                                           onChange={(e) => setIncome(e.target.value)} required="" onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            calculate();
                                        }
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <label className="form-label col-md-4 py-1">الإعفاء الشخصي</label>
                                <div className="col-md-8">
                                    <input type="number" className="form-control" placeholder="دخل الزوجة" value={9000} disabled={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <span className="link-primary" onClick={toggleExemeptions}>{
                            records.length > 0 ?
                                <>إلغاء الإعفاءات</>
                                :
                                <> إضافة الإعفاءات</>
                        }</span>
                    </div>
                    {
                        records.length > 0 &&
                        <div className="row mx-auto mt-4">
                            <TaxExemption records={records} setRecords={setRecords} supportedTypes={["husband"]} individual={true}/>
                        </div>
                    }
                    <div className="mt-4 row">
                        <div className="col-md-6 py-1">
                            <button className="btn btn-primary w-100" onClick={calculate} disabled={income < 1}>احتساب</button>
                        </div>
                        <div className="col-md-6 py-1">
                            <button className="btn btn-danger w-100" onClick={clear}>تفريغ الحقول</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                exemptions.total > 0 &&
                <div className="mt-4">
                    <h4>ملخص الإعفاءات</h4>
                    <ExemptionSummary exemptions={exemptions}/>
                </div>
            }

            {tax?.levels &&
            <div className="mt-4">
                <div className="row">
                    <div className="col">
                        <h5>الشرائح الضريبية للفرد</h5>
                        <Levels tax={tax}/>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Individual;