import {useEffect, useState} from "react";
import PriceDisplay from "../util/PriceDisplay";
import Levels from "./Levels";
import PreviousReadings from "./PreviousReadings";
import {useNavigate, useSearchParams} from "react-router-dom";

const newAidedValues = [
    {start: 0, end: 300, rate: 50},
    {start: 301, end: 600, rate: 100},
    {start: 601, end: -1, rate: 200}
];

const newValues = [
    {start: 0, end: 1000, rate: 120},
    {start: 1001, end: -1, rate: 150}
];

const oldValues = [
    {start: 0, end: 160, rate: 33},
    {start: 161, end: 300, rate: 72},
    {start: 301, end: 500, rate: 86},
    {start: 501, end: 600, rate: 114},
    {start: 601, end: 750, rate: 158},
    {start: 751, end: 1000, rate: 188},
    {start: 1000, end: -1, rate: 265},
];

const BillCalculator = function ({amount}) {
    const [value, setValue] = useState(amount || "");
    const [oldPrices, setOldPrices] = useState({});
    const [newAidedPrices, setNewAidedPrices] = useState({});
    const [newPrices, setNewPrices] = useState({});

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
        if (amount) {
            calculate()
        }
    }, []);

    const reset = function () {
        setValue(0);
        calculate();
    }

    const calculate = function () {
        const aid = value > 50 && value < 201 ? 2500: value > 200 && value < 601 ? 2000: 0;

        const oldResult = calculateValues(!value ? 0 : value, oldValues);
        const newAidedResult = calculateValues(!value ? 0 : value, newAidedValues, aid);
        const newResult = calculateValues(!value ? 0 : value, newValues, 0);

        setOldPrices(oldResult);
        setNewAidedPrices(newAidedResult);
        setNewPrices(newResult);

        let readings = window.localStorage.getItem("readings2");

        if (readings) {
            readings = JSON.parse(readings);
        } else {
            readings = [];
        }

        if (!readings.find(v => v.value === value)) {
            readings.push({value: value, oldTotal: oldResult.total, newTotal: newAidedResult.total, notAidedTotal: newResult.total});
        }

        window.localStorage.setItem("readings2", JSON.stringify(readings));

        const queryString = searchParams.toString();

        navigate("/electricity/bill/" + (value || 0) + (queryString? "?" + queryString : ""));
    }

    const calculateConsumption = function (value, values = []) {
        const result = {
            levels: [...values],
            total: 0
        };

        for (let i = 0; i < values.length; i++) {
            const level = result.levels[i];
            if (value > level.start) {
                let amount = (value - ((i === 0) ? 0 : i === values.length - 1 ? level.start : level.start - 1));
                if (value > level.end && level.end !== -1) {
                    amount = level.end - Math.max(0, level.start - 1);
                }
                level.amount = amount;
                level.price = (amount * level.rate);
                result.total = result.total + level.price;
            }
        }

        return result;
    }

    const calculateValues = function (value, values, aid = 0) {
        const result = {};
        const consumption = calculateConsumption(value, values);
        result.consumptionPrice = consumption.total;
        result.gasPricesDiff = 0;
        result.counterRate = 200;
        result.reef = parseInt(value);
        result.tv = 1000;
        result.trash = value > 200 ? (value - 200) * 5 + (20000 / 12) : 0;
        result.subtotal = (result.consumptionPrice + result.gasPricesDiff + result.counterRate + result.reef + result.tv + result.trash);
        result.levels = consumption;
        result.aid = aid
        result.total = result.subtotal - aid

        return result;
    }

    const moreSubtotalAided = oldPrices.subtotal === newAidedPrices.subtotal ? 0 : oldPrices.subtotal > newAidedPrices.subtotal ? -1 : 1;
    const moreSubtotal = oldPrices.subtotal === newPrices.subtotal ? 0 : oldPrices.subtotal > newPrices.subtotal ? -1 : 1;

    const moreAid = oldPrices.total === newAidedPrices.total ? 0 : oldPrices.total > newAidedPrices.total ? -1 : 1;
    const more = oldPrices.total === newPrices.total ? 0 : oldPrices.total > newPrices.total ? -1 : 1;

    return (
        <div className="mx-auto">
            <div className="card p-2 shadow-sm">
                <div className="input-group">
                    <input autoFocus type="number" className="form-control rounded-0" onKeyPress={event => {
                        if (event.key === 'Enter') {
                            calculate();
                        }
                    }} placeholder="كمية الاستهلاك (ك.و.س)" value={value} onChange={(e) => setValue(e.target.value)}/>
                    <button style={{width: 80}} className="btn btn-primary rounded-0" type="button" disabled={value === "" || value === 0} onClick={() => reset()}>مسح
                    </button>
                    <button style={{width: 80}} className="btn btn-success rounded-0" type="button" disabled={value === "" || value === 0} onClick={() => calculate()}>احتساب
                    </button>
                </div>
            </div>
            <table className="table table-bordered mt-4 shadow-sm bg-white">
                <thead>
                <tr>
                    <th>البيان</th>
                    <th>التعرفة القديمة</th>
                    <th>التعرفة الجديدة المدعومة<small className="sub align-top">(2022)</small></th>
                    <th>التعرفة الجديدة غير المدعومة<small className="sub align-top">(2022)</small></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>قيمة الإستهلاك</td>
                    <td><PriceDisplay price={oldPrices.consumptionPrice} currency={false}/></td>
                    <td><PriceDisplay price={newAidedPrices.consumptionPrice} currency={false}/></td>
                    <td><PriceDisplay price={newPrices.consumptionPrice} currency={false}/></td>
                </tr>
                <tr>
                    <td>فرق اسعار الوقود</td>
                    <td><PriceDisplay price={oldPrices.gasPricesDiff} currency={false}/></td>
                    <td><PriceDisplay price={newAidedPrices.gasPricesDiff} currency={false}/></td>
                    <td><PriceDisplay price={newPrices.gasPricesDiff} currency={false}/></td>
                </tr>
                <tr>
                    <td>اجرة العداد</td>
                    <td><PriceDisplay price={oldPrices.counterRate} currency={false}/></td>
                    <td><PriceDisplay price={newAidedPrices.counterRate} currency={false}/></td>
                    <td><PriceDisplay price={newPrices.counterRate} currency={false}/></td>
                </tr>
                <tr>
                    <td>فلس الريف</td>
                    <td><PriceDisplay price={oldPrices.reef} currency={false}/></td>
                    <td><PriceDisplay price={newAidedPrices.reef} currency={false}/></td>
                    <td><PriceDisplay price={newPrices.reef} currency={false}/></td>
                </tr>
                <tr>
                    <td>رسم التلفزيون</td>
                    <td><PriceDisplay price={oldPrices.tv} currency={false}/></td>
                    <td><PriceDisplay price={newAidedPrices.tv} currency={false}/></td>
                    <td><PriceDisplay price={newPrices.tv} currency={false}/></td>
                </tr>
                <tr>
                    <td>رسم النفايات</td>
                    <td><PriceDisplay price={oldPrices.trash} currency={false}/></td>
                    <td><PriceDisplay price={newAidedPrices.trash} currency={false}/></td>
                    <td><PriceDisplay price={newPrices.trash} currency={false}/></td>
                </tr>
                <tr>
                    <td>المجموع</td>
                    <td><PriceDisplay price={oldPrices.subtotal} currency={false}/></td>
                    <td className={moreSubtotal === -1 ? "text-success" : moreSubtotal === 1 ? "text-danger" : ""}><PriceDisplay price={newAidedPrices.subtotal} currency={false}/></td>
                    <td className={moreSubtotalAided === -1 ? "text-success" : moreSubtotalAided === 1 ? "text-danger" : ""}><PriceDisplay price={newPrices.subtotal} currency={false}/></td>
                </tr>
                <tr>
                    <td>الدعم الحكومي</td>
                    <td className="text-muted"><PriceDisplay price={oldPrices.aid} currency={false}/></td>
                    <td className={newAidedPrices.aid > 0? "text-primary": "text-muted"}><PriceDisplay price={newAidedPrices.aid} currency={false}/></td>
                    <td className="text-muted"><PriceDisplay price={newPrices.aid} currency={false}/></td>
                </tr>
                <tr>
                    <td>قيمة الفاتورة</td>
                    <td><PriceDisplay price={oldPrices.total}/></td>
                    <td className={moreAid === -1 ? "text-success" : more === 1 ? "text-danger" : ""}><PriceDisplay
                        price={newAidedPrices.total}/></td>
                    <td className={more === -1 ? "text-success" : more === 1 ? "text-danger" : ""}><PriceDisplay
                        price={newPrices.total}/></td>
                </tr>
                </tbody>
            </table>
            {oldPrices.levels?.levels &&
                <div className="mt-5">
                    <div className="row">
                        <div className="col-md-4">
                            <h5>التعرفة المطبقة القديمة</h5>
                            <Levels levels={oldPrices.levels?.levels}/>
                        </div>
                        <div className="col-md-4">
                            <h5>التعرفة المطبقة الجديدة المدعومة<small className="sub align-top">(2022)</small></h5>
                            <Levels levels={newAidedPrices.levels?.levels}/>
                        </div>
                        <div className="col-md-4">
                            <h5>التعرفة المطبقة الجديدة غير المدعومة<small className="sub align-top">(2022)</small></h5>
                            <Levels levels={newPrices.levels?.levels}/>
                        </div>
                    </div>
                </div>
            }
            <PreviousReadings/>
        </div>
    )
}

export default BillCalculator;